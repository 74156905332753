<template>
  <v-container fill-height fluid>
    <v-row align="center" justify="center">
      <v-col cols="8" md="4">
        <h1>Вход</h1>
        <UserLoginForm :submitForm="submitAuthData" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import UserLoginForm from '@/components/UserLoginForm'
import { mapActions } from 'vuex'

export default {
  components: {
    UserLoginForm
  },
  methods: {
    submitAuthData(username, password) {
      this.login({ username, password }).then(() => {
        this.$router.push({ name: 'home' })
      })
    },
    ...mapActions('auth', ['login'])
  }
}
</script>
