<template>
  <v-form ref="form" @submit.prevent="submitHandler">
    <v-text-field v-model="username" :rules="usernameRules" label="Имя пользователя" required />
    <v-text-field
      v-model="password"
      :rules="passwordRules"
      :type="showPassword ? 'text' : 'password'"
      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
      @click:append="showPassword = !showPassword"
      label="Пароль"
      required
    />
    <v-btn class="mt-4" type="submit">Войти</v-btn>
  </v-form>
</template>

<script>
export default {
  data: () => ({
    username: '',
    password: '',
    usernameRules: [(v) => !!v || 'Укажите имя пользователя'],
    passwordRules: [(v) => !!v || 'Укажите пароль'],
    showPassword: false
  }),
  props: ['submitForm'],
  methods: {
    submitHandler() {
      if (this.$refs.form.validate()) {
        this.submitForm(this.username, this.password)
      }
    }
  }
}
</script>
